const conditionsGenerales = {
  section1: {
    title: `Conditions d’utilisation et contrat de License Content ID`,
    description: `Il s’agit d’un contrat de licence exclusif nous permettant de monétiser
    vos titres avec le programme Content ID. Ce contrat vient compléter les
    Conditions Générales d’Utilisation d’un compte Dropzik. Cet accord
    s’applique aux titres que vous sélectionnez pour la monétisation de ce
    forfait uniquement, et nous créerons une nouvelle licence pour chaque
    forfait que vous voulez monétiser.`,
  },
  section2: {
    title: `Définitions`,
    items: [
      {
        title: `Vous`,
        description: ` le titulaire des droits ou le représentant légal des
        enregistrements (vous n’avez pas besoin d’être le créateur
        original des droits d’auteur).`
      },
      {
        title: `Nous`,
        description: `  Dropzik Digital INC, Canada.`
      },
      {
        title: `Content ID`,
        description: ` services qui identifient les enregistrements audios et
        détectent leur utilisation dans les vidéos ou les médias sociaux.`
      },
      {
        title: `Titre`,
        description: ` une chanson ou une piste audio.`
      },
    ]
  },
  section3: {
    title: `Garanties`,
    items: [
      `Vous garantissez que vous avez le droit de gérer les droits de propriété
      intellectuelle/Copyright des titres de ce forfait.`,
    ]
  },
  section4: {
    title: `Terme du contrat`,
    items: [
      `Il s'agit d&#39;un contrat à durée indéterminée, ce qui signifie que vous
      pouvez décider vous-même quand y mettre fin et arrêter la
      monétisation. Voir la section « Résiliation » pour connaître les modalité
      de résiliation de la licence.`,
      `La résiliation de cette licence entraîne la fin de la monétisation. L&#39;une ou
      l'autre des parties peut résilier cette licence à tout moment. Les
      résiliations peuvent être faites par écrit (un courrier électronique suffit).`
    ],
  },
  section5: {
    title: `Exclusivité et cession`,
    items: [
      `Cette licence peut faire l’objet d’une cession, qui est exclusive pour la
      monétisation avec le programme Content ID des enregistrements pour
      tous les titres de ce forfait.`,
    ]
  },
  section6: {
    title: `Revenus et commissions`,
    items: [
      `Dès que nous aurons reçu les relevés/revenus des services
      d'identification de contenu, vos revenus ainsi que vos relevés seront mis
      à disposition sur votre compte. Si une commission est applicable, nous
      la déduisons avant de mettre en ligne vos revenus.`,
    ]
  },
  section7: {
    title: `Territoires`,
    description: `Le choix des territoires est établi par vous-même.`,
  },
  section8: {
    title: `Contrôle qualité et contenu douteux`,
    description: `Nous nous réservons le droit de modifier vos informations en fonction
    des meilleures pratiques et des et des évolutions des formats standards
    de l’industrie musicale. De plus, nous nous réservons le droit de refuser
    la monétisation de vos titres en fonction de la qualité de l’audio ou de
    son contenu. Le contenu douteux comprend des éléments tels que des
    paroles ou des illustrations racistes ou politiquement sensibles, de la
    pornographie, etc.`,
  },
  section9: {
    title: `Atteinte aux droits de propriété intellectuelle (incluant le système du Copyright) et activité frauduleuse`,
    description: `Nous avons des politiques strictes en matière de violation des droits de propriété intellectuelle/ du Copyright et d'activités frauduleuses. De telles violations de ces droits incluent mais ne sont pas limitées à :`,
    items: [
      `Monétisation de pistes pour lesquelles vous n&#39;êtes pas autorisé à monétiser.`,
      `Monétiser des enregistrements avec des samples pour lesquels aucune autorisation n’a été donnée.`,
      `Des données inexactes, en particulier au sujet des titulaires des droits de propriété intellectuelle/Copyright.`
    ]
  },
  section10: {
    description: `Les activités frauduleuses comprennent, sans toutefois s&#39;y limiter:`,
    items: [
      `Enrichissement frauduleux par augmentation artificielle des écoutes et/ou des téléchargements.`,
      `L’utilisation non autorisée de pistes, noms d&#39;artistes, marques de commerce et autres qui pourraient vous donner un avantage commercial.`,
      `Tromperie par la création de plusieurs comptes Dropzik.`,
    ],
  },
  section11: {
    description: `Dans le cas où nous aurions conclu que vous auriez enfreint l’une de ces réglementations, nous nous réservons le droit de :`,
    items: [
      `Divulguer vos données personnelles aux autorités compétentes qui s’occupent du litige ou de la situation.`,
      `Geler et/ou fermer votre compte Dropzik.`,
      `Geler le versement de vos revenus, retenir le versement de vos revenus, ou même exiger le remboursement des revenus déjà versés pour les titres litigieux ou l’ensemble de vos titres.`,
      `Retirer des plateformes vos titres offensants ou l’ensemble de vos titres.`,
      `Refuser votre droit à un remboursement.`,
      `Facturer une pénalité pouvant aller jusqu'à 150 CAD par piste impliquée dans la violation des droits de propriété intellectuelle/Copyright ou par activité frauduleuse.`,
    ],
  }
};

export default conditionsGenerales;